/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'CI',
  key: 1,
  newsSectionUrl: 'https://m.weezchat-ci.com/news',
  footerText: null,
  footerLinks: [
    { value: 'Mentions légales', href: 'http://m.weezchat-ci.com/mentions_legales' },
    { value: 'Mon abonnement', href: 'http://www.mes-abonnements-ci.com/' }
  ],
  LP: 'http://promo.weezchat-ci.com/pwzchatci/lp_weetic/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 500,
  gtm: 'GTM-TGQ4KT',
  showAllCities: true,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'http://www.mes-abonnements-ci.com/',
freeSubscription : false,
  home: {
    app: {
      available: true,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.tchat.weezchat&hl=fr',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/WeezchatCI/?ref=bookmarks'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/weezchat_ci?lang=fr'
    },
    instagram: {
      available: true,
      link: 'https://www.instagram.com/weezchatcotedivoire/'
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://www.dgp-legal.com/en/cookies-information/'
    }
  }
};
